import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'shared/redux-store';
import { hide, ModalTypes } from 'slices/modal';
import isEmpty from 'lodash/isEmpty';
import Modal from 'components/Modal/Modal';
import Form from './components/Form';
import Success from './components/Success';
import React, { useState } from 'react';

import { BalanceResponseDto, UserDto } from 'dtos';
import { UserWithBalanceInfo } from 'components/UserWithBalanceInfo';
import styles from './CreateBalanceTransactionModal.module.scss'

export interface TransactionData {
  contact: UserDto;
  value?: string;
  code?: string;
  comment?: string;
  type?: BalanceType;
  isSending?: boolean;
  sent?: boolean;
  balance?: BalanceResponseDto;
}

export enum BalanceType {
  Send = 'send',
  Receive = 'receive',
}

function CreateBalanceTransactionModal(): JSX.Element | null {
  const dispatch = useDispatch();

  const { visible, data: modalData } = useSelector(
    (state: RootState) => state.modal[ModalTypes.CreateBalanceTransaction]
  );
  const data = { ...modalData } as TransactionData;
  const [transactionData, setTransactionData] = useState({ ...data });

  if (data?.contact?.id !== undefined && isEmpty(transactionData)) {
    setTransactionData(data);
  }

  const setData = (data: TransactionData) => {
    setTransactionData(data);
  };

  if (!visible || isEmpty(modalData)) {
    return null;
  }

  const closeModal = (): void => {
    setTransactionData({} as TransactionData);
    dispatch(hide({ id: ModalTypes.CreateBalanceTransaction }));
  };

  return (
    <Modal visible={visible} onClose={closeModal} className={styles.modal}>
      {!transactionData.sent ? (
        <>
          <UserWithBalanceInfo user={data.contact} balance={data.balance} />

          <Form data={transactionData} onSetData={setData} onClose={closeModal} />
        </>
      ) : (
        <Success data={transactionData} onSuccess={closeModal} visible={visible}/>
      )}
    </Modal>
  );
}

export default CreateBalanceTransactionModal;
